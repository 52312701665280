import { render, staticRenderFns } from "./AdministrativeManagementEdit.vue?vue&type=template&id=14391670&scoped=true&"
import script from "./AdministrativeManagementEdit.vue?vue&type=script&lang=js&"
export * from "./AdministrativeManagementEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14391670",
  null
  
)

export default component.exports